import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
// api
import {
  removeExtendedInsightsResponsibleUser,
  removeTreatmentProtocolsResponsibleContact,
  setExtendedInsightsResponsibleUser,
  setTreatmentProtocolsResponsibleContact,
  removeReportsRestrictedUser,
  setReportsRestrictedUser,
  setCloseoutEmailUser,
  removeCloseoutEmailUser,
} from 'endpoints/tenantSettings';
// constants
import { hours, utcOffsets } from 'constants.js';

export const settingsLabels = {
  panelTitle: <FormattedMessage id="general.settings" />,
  updateSuccess: <FormattedMessage id="general.settings.updateSuccess" />,
  save: <FormattedMessage id="general.button.save" />,
  sms: {
    text: <FormattedMessage id="component.settings.smsComplianceMessaging" />,
    description: <FormattedMessage id="component.settings.smsComplianceDescription" />,
    remindHour: <FormattedMessage id="general.remindHour" />,
    timeZone: <FormattedMessage id="general.timeZone" />,
    reminderInfo: (hour, utcOffset) => (
      <FormattedMessage
        id="component.settings.smsComplianceMessagingInfoText"
        values={{
          hour: hours.find(({ value }) => value === hour)?.label || 'N/A',
          utcOffset: utcOffsets.find(({ value }) => value === utcOffset)?.label || 'N/A',
          b: (msg) => <b>{msg}</b>
        }}
      />
    )
  },
  inventory: {
    text: <FormattedMessage id="component.settings.invReconciliation" />,
    description: <FormattedMessage id="component.settings.invReconciliationDescription" />,
    reconciliationTimeFrame: <FormattedMessage id="component.settings.reconciliationTimeFrame" />,
    reconciliationInfo: (val) => (
      <FormattedMessage
        id="component.settings.invReconciliationInfoText"
        values={{
          weekDay: val,
          b: (msg) => <b>{msg}</b>
        }}
      />
    ),
  },
  extendedInsights: {
    text: <FormattedMessage id="general.extendedInsightsUsers" />,
    description: <FormattedMessage id="general.extendedInsightsUsers.desc" />,
  },
  reportsRestrictedUsers: {
    text: <FormattedMessage id="general.reportsRestrictedUsers" />,
    description: <FormattedMessage id="general.reportsRestrictedUsers.desc" />,
  },
  treatmentCompliance: {
    text: <FormattedMessage id="component.settings.treatmentCompliance" />,
    description: <FormattedMessage id="component.settings.treatmentComplianceDescription" />,
    days: <FormattedMessage id="component.settings.treatmentComplianceDays" />,
  },
  treatmentProtocols: {
    text: <FormattedMessage id="general.treatmentProtocols" />,
    description: <FormattedMessage id="component.settings.treatmentProtocolsDescription" />,
    responsibleUsers: {
      text: <FormattedMessage id="general.responsibleUsers" />,
      description: <FormattedMessage id="component.settings.treatmentProtocolsResponsibleUsers.desc" />,
    }
  },
  barnSheetsEditingConstraints: {
    text: <FormattedMessage id="component.settings.barnsheetsEditingConstraint" />,
    description: <FormattedMessage id="component.settings.barnsheetsEditingConstraint.desc" />,
  },
  emails: {
    text: <FormattedMessage id="general.pageTitle.autoReport" />,
    description: <FormattedMessage id="component.settings.autoReportDescription" />,
    autoReportTimeFrame: <FormattedMessage id="component.settings.autoReportTimeFrame" />
  },
  withdrawal: {
    text: <FormattedMessage id="component.settings.trackWithdrawalPeriods" />,
    description: <FormattedMessage id="component.settings.trackWithdrawalDescription" />,
    withdrawalWeightThreshold: <FormattedMessage id="component.settings.withdrawalWeightThreshold" />,
    info: (val) => (
      <FormattedMessage
        id="component.settings.trackWithdrawalInfoText"
        values={{ weight: <strong>{val}</strong>, measure: <FormattedWeight hasOnlyUnit /> }}
      />
    ),
  },
  customSurvey: {
    text: <FormattedMessage id="component.settings.customSurvey" />,
    description: <FormattedMessage id="component.settings.customSurveyDesc" />,
  },
  mortality: {
    text: <FormattedMessage id="component.settings.trackMortalityReasons" />,
    description: <FormattedMessage id="component.settings.mortalityReasonsDescription" />,
    manage: <FormattedMessage id="general.manageMortalityReasons" />,
  },
  comments: {
    text: <FormattedMessage id="component.settings.commentCompanyDirectPost" />,
    description: <FormattedMessage id="component.settings.commentCompanyDirectPostDescription" />,
  },
  autoReportMortalityPercentage: {
    text: <FormattedMessage id="component.settings.autoReportMortalityPercentage" />,
    description: <FormattedMessage id="component.settings.autoReportMortalityPercentage.desc" />,
  },
  pigGroupsCompliance: {
    text: <FormattedMessage id="component.settings.treatmentProtocolsComplianceMessaging" />,
    description: <FormattedMessage id="component.settings.treatmentProtocolsComplianceMessaging.desc" />,
  },
  complianceReport: {
    text: <FormattedMessage id="general.complianceReport" />,
    description: <FormattedMessage id="general.complianceReport.desc" />,
  },
  complianceFarmReport: {
    text: <FormattedMessage id="general.complianceFarmReport" />,
    description: <FormattedMessage id="general.complianceFarmReport.desc" />,
  },
  reportsDailySummary: {
    text: <FormattedMessage id="general.reportsDailySummary" />,
    description: <FormattedMessage id="general.reportsDailySummary.desc" />,
  },
  caregiverMediaUpload: {
    text: <FormattedMessage id="general.caregiverMediaUpload" />,
    description: <FormattedMessage id="general.caregiverMediaUpload.desc" />,
  },
  waterConsumptionWarnings: {
    text: <FormattedMessage id="general.waterConsumptionWarnings" />,
    description: <FormattedMessage id="general.waterConsumptionWarnings.desc" />,
  },
  generalWithdrawalWarnings: {
    text: <FormattedMessage id="general.generalWithdrawalWarnings" />,
  },
  symptomPrompt: {
    text: <FormattedMessage id="component.settings.symptomPrompt" />,
    description: <FormattedMessage id="component.settings.symptomPromptDescription" />,
    days: <FormattedMessage id="component.settings.symptomPromptDays" />,
  },
  abfTracking: {
    text: <FormattedMessage id="component.settings.abfGroupTracking" />,
    description: <FormattedMessage id="component.settings.abfGroupTrackingDescription" />,
  },
  trackFeedOrders: {
    text: <FormattedMessage id="component.settings.trackFeedOrders" />,
    description: <FormattedMessage id="component.settings.trackFeedOrdersDescription" />,
  },
  closeoutEmails: {
    text: <FormattedMessage id="general.closeoutEmailUsers" />,
    description: <FormattedMessage id="general.closeoutEmailUsers.desc" />,
  },
};

export const getOptLabel = (options, value) => (options.find((opt) => opt.value === value) || {}).label;

export const filterOption = (selectedEntities) => () => (option) => {
  const isAlreadySelected = !!selectedEntities.find((entity) => entity.user.id === option.value);
  if (isAlreadySelected) return null;
  return option;
};

export const optionsMeasures = [
  { label: <FormattedMessage id="container.setPoints.system.usStandard" />, value: 'imperial' },
  { label: <FormattedMessage id="container.setPoints.system.metric" />, value: 'metric' },
];

export const optionsTemps = [
  { label: <FormattedMessage id="general.fahrenheit" />, value: 'fahrenheit' },
  { label: <FormattedMessage id="general.celsius" />, value: 'celsius' },
];

export const responsibleUsersRequestMapper = {
  treatment_protocol_contacts: {
    set: setTreatmentProtocolsResponsibleContact,
    remove: removeTreatmentProtocolsResponsibleContact,
  },
  company_analytics_users: {
    set: setExtendedInsightsResponsibleUser,
    remove: removeExtendedInsightsResponsibleUser,
  },
  company_svrs_disabled_users: {
    set: setReportsRestrictedUser,
    remove: removeReportsRestrictedUser,
  },
  company_closeout_email_users: {
    set: setCloseoutEmailUser,
    remove: removeCloseoutEmailUser,
  }
};
