import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux, actions, recompose
import { connect } from 'react-redux';
// component
import SearchBox from "components/SearchBox";
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import NothingBox from 'components/NothingBox';
import { CustomColumn } from 'components/DataTable/Columns';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// hooks
import useDataTableController from 'hooks/useDataTableController';
// assets
import './FarmBins.scss';

const labels = {
  dataTable: {
    binId: <FormattedMessage id="component.dataTable.headers.binID" />,
    capacity: <FormattedMessage id="component.dataTable.headers.capacity" />,
    fillLevel: <FormattedMessage id="component.dataTable.headers.currentFillLevel" />,
  },
  bins: <FormattedMessage id="general.bins" />,
};

const FarmBins = ({
  fetchData,
  isLoading,
  meta,
  params = {},
  resources,
  reqParams,
}) => {
  const [isBarnUpdating, setIsBarnUpdating] = useState(false);
  const { onPageChange, onPerPageChange, onSearchChange, onSortChange } = useDataTableController(fetchData, params);
  const { search, sort, page, per_page } = reqParams;

  const paginationProps = {
    totalItems: meta.total,
    currentPage: page,
    perPage: per_page,
    onPageChange,
    onPerPageChange,
  };

  useEffect(() => {
    fetchData(reqParams).catch(toastResponseErrors);
  }, []);

  const renderBinIdColumn = ({ binId }) => {
    return (
      <CustomColumn flexRow>
        {binId}
      </CustomColumn>
    );
  };

  const renderUserRoleColumn = ({ capacity }) => (
    <CustomColumn flexRow label={labels.dataTable.capacity}>
      {capacity || '-'}
    </CustomColumn>
  );

  const renderCurrentFillLevel = ({ currentAmount }) => (
    <CustomColumn noBottomBorder label={labels.dataTable.fillLevel}>
      {currentAmount}
    </CustomColumn>
  );

  const columns = [
    { label: labels.dataTable.binId, flex: '1 0 250px', renderer: renderBinIdColumn, sortKey: 'binId' },
    { label: labels.dataTable.capacity, flex: '1 0 100px', renderer: renderUserRoleColumn, sortKey: 'capacity' },
    { label: labels.dataTable.fillLevel, flex: '1 0 100px', renderer: renderCurrentFillLevel, sortKey: 'currentAmount' },
  ];

  return (
    <Panel className="FarmBins">
      <Panel.Heading title={labels.bins}>
        <SearchBox initialValue={search} onChange={onSearchChange} />
      </Panel.Heading>
      <Panel.Body noPadding>
        <Preloader isActive={isLoading || isBarnUpdating} />
        <DataTable
          paginationProps={paginationProps}
          onSortChange={onSortChange}
          data={resources}
          columns={columns}
          scrollable
          tableKey="farmBins"
        />
        <NothingBox itemsName="bins" display={!resources.length} isLoading={isLoading}>
          <FormattedMessage tagName="h1" id="component.nothingBox.farmHasNoBins" />
        </NothingBox>
      </Panel.Body>
    </Panel>
  );
}

FarmBins.propTypes = {
  resources: T.array.isRequired,
  fetchData: T.func.isRequired,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  params: T.object,
};

export default connect(
  (state) => ({
    resources: mockedBins,
    reqParams: {
      search: '',
      sort: '',
      page: 1,
      per_page: 10,
    },
    meta: {
      total: 3,
    },
    isLoading: state.dataTable.farmBins.isLoading,
  }), (dispatch, { endpoint, tableKey }) => ({
    // TODO: replace fetchMokedBins with fetchData redux action
    fetchData: (query) => fetchMockedBins(endpoint, tableKey, query),
  }),
)(FarmBins);


const mockedBins = [
  { id: 1, binId: 'Bin 1', capacity: 100, currentAmount: 10 },
  { id: 2, binId: 'Bin 2', capacity: 200, currentAmount: 25 },
  { id: 3, binId: 'Bin 3', capacity: 300, currentAmount: 35 },
];

// TODO: replace it with actual query
const fetchMockedBins = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
        resolve({ data: mockedBins });
      }, 1000);
    });
}
