import { handleActions, createAction, combineActions } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------
const tableKeys = ['farmAntibiotics', 'antibiotics', 'farmsAntibiotics', 'groupAntibiotics', 'treatmentFeedbacks',
  'treatmentEfficacy', 'groupTreatmentFeedback', 'adminGroups', 'renameGroups', 'importGroups',
  'barnsheetsGroupTreatments', 'barnsheetsFarmTreatments', 'barnsheetsWaterUsage',
  'barnsheetsCompanyFarms', 'myAdminRoles', 'myCompanyRoles', 'myFarmsRoles', 'adminUsers', 'userFarmRoles',
  'userFarmRolesManage', 'userCompanyRoles', 'userCompanyRolesManage', 'farmUserRoles', 'adminFarms',
  'farmUserRolesManage', 'adminCompanies', 'companyUserRoles', 'companyUserRolesManage', 'companyFarms',
  'companyFarmsManage', 'companyGroups', 'myCompanyAdmins', 'myCompanyGroups', 'myCompanyUserRoles',
  'myCompanyUserRolesManage', 'myCompanyFarms', 'myCompanyFarmsManage', 'adminMedications', 'adminSymptoms',
  'adminMortalityReasons', 'adminDiseases', 'sources', 'sourceGroups', 'adminDestinations', 'tenants', 'superAdmins',
  'adminShareholderGroups', 'adminShareholderGroupFarms', 'adminShareholderGroupFarmsManage', 'sourceTreatmentFeedback',
  'farmsCheckins', 'tenantAssets', 'farmBarns', 'truckingCompanies', 'packingCompanies', 'packingPlants',
  'truckingRoles', 'truckingRolesManage', 'userTruckingRoles', 'userTruckingRolesManage', 'loadings',
  'adminGenetics', 'geneticGroups', 'csvImport', 'usersImportResult', 'farmsImportResult', 'barnsImportResult',
  'groupsImportResult', 'reportFarms', 'adminReportTemplates', 'reportTemplates', 'farmBins'];

const defaultParams = {
  page: 1,
  per_page: 25,
  search: '',
  sort: '',
  status: '',
};

const metaFarms = {
  meta: {
    total: 0,
    errors: 0,
    active: false,
  },
  resources: {
    data: {
      report_data: {
        death: 0,
        euthanize: 0,
      },
    }
  },
  params: defaultParams,
  isLoading: false,
  logs: [],
};
const importsData = {
  meta: {
    total: 0,
  },
  resources: [],
  params: defaultParams,
  isLoading: false,
  logs: [],
};
const fasLink = {
  meta: {
    total: 0,
    errors: 0,
    active: false,
  },
  resources: {
    data: {
      report_data: {
        est_avg_weight: 0,
        mortalities: 0,
        placements: 0,
        sales: 0,
        transfers: 0,
      }
    }
  },
  params: defaultParams,
  isLoading: false,
  logs: [],
};
const fasLinkPull = {
  meta: {
    total: 0,
    errors: 0,
    active: false,
  },
  resources: {
    farm_name: '',
    data: {
      report_data: {
        feed_based_meds: [],
        head_treated: 0,
        imported: 0,
      }
    }
  },
  params: defaultParams,
  isLoading: false,
  logs: [],
};

const initialTableData = {
  resources: [],
  isLoading: false,
  params: { ...defaultParams },
  meta: {
    total: 0,
    stats: {},
  },
};

const farmsMetaFarms = {
  meta: {
    total: 0,
    created: 0,
    updated: 0,
    errors: 0,
  },
  resources: [],
  params: { ...defaultParams, status: 'created' },
  isLoading: false,
};
const groupsMetaFarms = {
  meta: {
    total: 0,
    created: 0,
    updated: 0,
    errors: 0,
  },
  resources: [],
  params: { ...defaultParams, status: 'created' },
  isLoading: false,
};

const farmsFas = {
  meta: {
    total: 0,
    created: 0,
    updated: 0,
    errors: 0,
  },
  resources: [],
  params: { ...defaultParams, status: 'created' },
  isLoading: false,
};

const groupsFas = {
  meta: {
    total: 0,
    created: 0,
    updated: 0,
    errors: 0,
  },
  resources: [],
  params: { ...defaultParams, status: 'created' },
  isLoading: false,
};

const companiesFas = {
  meta: {
    total: 0,
    created: 0,
    updated: 0,
    errors: 0,
  },
  resources: [],
  params: { ...defaultParams, status: 'created' },
  isLoading: false,
};

const initialState = {
  ...tableKeys.reduce((obj, item) => ({ ...obj, [item]: initialTableData }), {}),
  metaFarms, fasLink, fasLinkPull, importsData, companiesFas, groupsFas, farmsFas, groupsMetaFarms, farmsMetaFarms,
};
const RESET_RESOURCES = 'dataTable/RESET_RESOURCES';
const LOAD_MORE_DATA = 'dataTable/LOAD_MORE_DATA';
const MERGE_DATA_ITEM = 'dataTable/MERGE_DATA_ITEM';
const SET_DATA_ITEM_BY_INDEX = 'dataTable/SET_DATA_ITEM_BY_INDEX';
const REMOVE_DATA_ITEM_BY_INDEX = 'dataTable/REMOVE_DATA_ITEM_BY_INDEX';
const UPDATE_DATA_ITEM = 'dataTable/UPDATE_DATA_ITEM';
const UPDATE_DATA_ITEM_PENDING = 'dataTable/UPDATE_DATA_ITEM_PENDING';
const UPDATE_DATA_ITEM_FULFILLED = 'dataTable/UPDATE_DATA_ITEM_FULFILLED';
const UPDATE_DATA_ITEM_REJECTED = 'dataTable/UPDATE_DATA_ITEM_REJECTED';
const FETCH_DATA = 'dataTable/FETCH_DATA';
const [FETCH_DATA_PENDING, FETCH_DATA_FULFILLED, FETCH_DATA_REJECTED] = [
  `${FETCH_DATA}_PENDING`,
  `${FETCH_DATA}_FULFILLED`,
  `${FETCH_DATA}_REJECTED`,
];
const UPDATE_META = 'dataTable/UPDATE_META';
const UPDATE_DATA = 'dataTable/UPDATE_RESOURCES';
const SET_VIEWED_RESOURCES = 'dataTable/SET_VIEWED_RESOURCES';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchData = (path, tableKey, params = defaultParams, isLoadMore) => (dispatch) => {
  dispatch(createAction(FETCH_DATA_PENDING)({ tableKey }));

  const action = isLoadMore ? LOAD_MORE_DATA : FETCH_DATA_FULFILLED;
  return fetchFromAPI(path, { params: { ...params, search: encodeURIComponent(params?.search || '') } })
    .then((response) => {
      dispatch(createAction(action)({ ...response, params, tableKey }));
      return response;
    })
    .catch((response) => {
      dispatch(createAction(FETCH_DATA_REJECTED)({ tableKey }));
      throw response;
    });
};

export const setDataItem = (resource, tableKey) => createAction(UPDATE_DATA_ITEM)({ resource, tableKey });

export const setDataItemByIndex = (resource, index, tableKey) => createAction(SET_DATA_ITEM_BY_INDEX)(
  { resource, tableKey, index }
);

export const removeDataItemByIndex = (index, tableKey) => createAction(REMOVE_DATA_ITEM_BY_INDEX)(
  { tableKey, index }
);

export const mergeDataItemToTop = (resource, tableKey) => createAction(MERGE_DATA_ITEM)({ resource, tableKey });

export const updateMeta = (meta, tableKey) => createAction(UPDATE_META)({ meta, tableKey });

export const updateData = (response, tableKey) => createAction(UPDATE_DATA)({ response, tableKey });

export const resetResources = (tableKey) => createAction(RESET_RESOURCES)({ tableKey });

// for viewed/unviewed feature
export const setViewedResources = (viewedIds, tableKey) => createAction(SET_VIEWED_RESOURCES)({ viewedIds, tableKey });

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [combineActions(FETCH_DATA_PENDING, UPDATE_DATA_ITEM_PENDING)]: (state, { payload }) => ({
    ...state,
    [payload.tableKey]: {
      ...state[payload.tableKey],
      isLoading: true,
    },
  }),
  [FETCH_DATA_FULFILLED]: (state, { payload }) => ({
    ...state,
    [payload.tableKey]: {
      ...state[payload.tableKey],
      resources: payload.resources,
      meta: payload.meta,
      params: payload.params,
      isLoading: false,
    },
  }),

  [combineActions(FETCH_DATA_REJECTED, UPDATE_DATA_ITEM_REJECTED)]: (state, { payload }) => ({
    ...state,
    [payload.tableKey]: {
      ...state[payload.tableKey],
      isLoading: false,
    },
  }),
  [combineActions(UPDATE_DATA_ITEM, UPDATE_DATA_ITEM_FULFILLED)]: (state, { payload: { resource, tableKey } }) => {
    const updatedItem = state[tableKey].resources.find(({ id }) => id === resource.id);
    const updatedItemIndex = state[tableKey].resources.indexOf(updatedItem);
    return ({
      ...state,
      [tableKey]: {
        ...state[tableKey],
        resources: [
          ...state[tableKey].resources.slice(0, updatedItemIndex),
          { ...updatedItem, ...resource },
          ...state[tableKey].resources.slice(updatedItemIndex + 1),
        ],
        isLoading: false,
      },
    });
  },
  [SET_DATA_ITEM_BY_INDEX]: (state, { payload: { resource, tableKey, index } }) => ({
    ...state,
    [tableKey]: {
      ...state[tableKey],
      resources: [
        ...state[tableKey].resources.slice(0, index),
        resource,
        ...state[tableKey].resources.slice(index + 1),
      ],
    },
  }),
  [REMOVE_DATA_ITEM_BY_INDEX]: (state, { payload: { tableKey, index } }) => ({
    ...state,
    [tableKey]: {
      ...state[tableKey],
      resources: [
        ...state[tableKey].resources.slice(0, index),
        ...state[tableKey].resources.slice(index + 1),
      ],
      params: {
        ...state[tableKey].params,
        padding: state[tableKey].params.padding - 1,
      },
      meta: {
        ...state[tableKey].meta,
        total: state[tableKey].meta.total - 1,
      }
    },
  }),
  [MERGE_DATA_ITEM]: (state, { payload: { resource, tableKey } }) => ({
    ...state,
    [tableKey]: {
      ...state[tableKey],
      resources: [resource, ...state[tableKey].resources],
      params: {
        ...state[tableKey].params,
        padding: state[tableKey].params.padding + 1,
      },
      meta: {
        ...state[tableKey].meta,
        total: state[tableKey].meta.total + 1,
      },
    },
  }),
  [LOAD_MORE_DATA]: (state, { payload }) => ({
    ...state,
    [payload.tableKey]: {
      ...state[payload.tableKey],
      resources: [
        ...state[payload.tableKey].resources,
        ...payload.resources
      ],
      params: payload.params,
      meta: payload.meta,
      isLoading: false,
    },
  }),
  [RESET_RESOURCES]: (state, { payload }) => ({
    ...state,
    [payload.tableKey]: {
      ...state[payload.tableKey],
      resources: [],
      meta: {
        total: 0,
        stats: {},
      },
    },
  }),
  [UPDATE_META]: (state, { payload }) => ({
    ...state,
    [payload.tableKey]: {
      ...state[payload.tableKey],
      meta: {
        ...state[payload.tableKey].meta,
        ...payload.meta,
      },
    },
  }),
  [UPDATE_DATA]: (state, { payload }) => ({
    ...state,
    [payload.tableKey]: {
      ...state[payload.tableKey],
      ...payload.response,
    },
  }),
  [SET_VIEWED_RESOURCES]: (state, { payload }) => {
    const resources = state[payload.tableKey].resources.map((item) => {
      return payload.viewedIds.has(item.id) ? { ...item, is_viewed: true } : item;
    });
    return {
      ...state,
      [payload.tableKey]: {
        ...state[payload.tableKey],
        resources,
      },
    };
  },

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
