import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect';
import Button from 'components/Button';
import Input from 'components/Input';
import AttentionBox from 'components/AttentionBox/AttentionBox';
import Panel from 'components/Panel';
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import TenantSettingTogglePanel from 'components/TenantSettingTogglePanel/TenantSettingTogglePanel';
import ToggleSwitcher from 'components/ToggleSwitcher';
import DefaultMapCenterSection from './DefaultMapCenterSection';
import AsyncMultiSelect from 'components/AsyncMultiSelect';
import MultiInput from 'components/MultiInput';
import ReactSelect from 'components/Select/ReactSelect';
import { BasicPlanCustomizationBlocker } from 'components/BasicPlan';
import MessageBox from 'components/MessageBox';
// utils, constants
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
import { formatWeightToBackEndFormat } from 'utils/weightHelper';
import {
  settingsLabels,
  optionsTemps,
  optionsMeasures,
  responsibleUsersRequestMapper,
  filterOption,
  getOptLabel,
} from './utils';
import {
  inventoryReconciliationOptions,
  withdrawalWeightOptions,
  autoReportPeriodOptions,
  utcOffsets,
  hours,
} from 'constants.js';
// styles
import './Settings.scss';

const Settings = ({
  tenant_settings,
  updateSettings,
  setSettings,
  isTreatmentProtocolsFeatureEnabled,
  isSVREnabled,
}) => {
  const {
    sms_compliance,
    inventory_reconciliation_active,
    inventory_reconciliation,
    auto_report_emails,
    auto_report_period,
    auto_report_mortality_percentage,
    withdrawal_tracking_on,
    withdrawal_weight_limit,
    new_withdrawal_weight_limit,
    track_mortality_reasons,
    comment_company_direct_post,
    measurement_system,
    temperature_system,
    treatment_protocols,
    treatment_protocol_contacts,
    barnsheets_limited_access,
    farm_compliance_utc_offset,
    farm_compliance_hour,
    company_analytics_users,
    company_svrs_disabled_users,
    analytics_extended_access,
    svrs_limited_access,
    treatment_protocols_pg_compliance,
    svrs_compliance_emails,
    farms_compliance_emails,
    media_upload,
    general_water_usage_warnings,
    general_withdrawal_warnings,
    svrs_daily_summary_emails,
    svrs_summary_emails,
    treatment_compliance,
    treatment_compliance_days,
    new_treatment_compliance_days,
    symptom_prompt,
    symptom_prompt_days,
    new_symptom_prompt_days,
    abf_tracking,
    closeout_emails,
    company_closeout_email_users,
    // TODO: change key to appropriate one
    feed_tracking,
  } = tenant_settings;

  const setWeight = (e) => {
    e.persist();
    const reg = /^[0-9]{1,5}$/g;
    if (e.target.value === '' || reg.test(e.target.value)) {
      setSettings({
        new_withdrawal_weight_limit: Number(e.target.value)
      });
    }
  };

  const setTreatmentComplianceDays = (e) => {
    e.persist();
    const reg = /^[0-9]{1,3}$/g;
    if (e.target.value === '' || reg.test(e.target.value)) {
      setSettings({
        new_treatment_compliance_days: Number(e.target.value)
      });
    }
  };

  const setSymptomPromptDays = (e) => {
    e.persist();
    const reg = /^[0-9]{1,3}$/g;
    if (e.target.value === '' || reg.test(e.target.value)) {
      setSettings({
        new_symptom_prompt_days: Number(e.target.value)
      });
    }
  };

  const handleSelectChange = (key) => (value) => {
    updateSettings({ [key]: value });
  };

  const getSubmitResource = (key, value) => {
    if (key === 'inventory_reconciliation_active') {
      return { inventory_reconciliation: value ? tenant_settings.inventory_reconciliation : 'disabled' };
    }
    return { [key]: value };
  };

  const handleSettingChange = ({ target: { name, checked } }) => {
    const resource = getSubmitResource(name, checked);
    updateSettings(resource);
  };

  const renderToggleTitle = (labelKey, valueLabel = '') => (
    <div className="toggleTitle">
      <FormattedMessage
        tagName="b"
        id={`general.settings.${labelKey}`}
        values={{ label: <b className="primary">{valueLabel}</b> }}
      />
      <p className="toggleSub">
        <FormattedMessage
          id={`general.settings.${labelKey}Info`}
          values={{ label: valueLabel }}
        />
      </p>
    </div>
  );

  const addUserToMultiSelectField = (fieldName) => ({ value }) => {
    const { set } = responsibleUsersRequestMapper[fieldName];
    const fieldValue = tenant_settings[fieldName];
    set({ user_id: value })
      .then((resource) => setSettings({ [fieldName]: [...fieldValue, resource] }))
      .catch(toastResponseErrors);
  };

  const removeUserFromMultiSelectField = (fieldName) => ({ id }) => {
    const { remove } = responsibleUsersRequestMapper[fieldName];
    const fieldValue = tenant_settings[fieldName];
    const newArray = fieldValue.filter((user) => user.id !== id);
    remove(id)
      .then(() => setSettings({ [fieldName]: newArray }))
      .catch(toastResponseErrors);
  };

  const validateEmail = (value) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return re.test(value);
  };

  const addEmailToSummaryEmails = (email) => {
    const fieldValue = tenant_settings.svrs_summary_emails;
    if (!fieldValue.includes(email)) {
      const newEmails = [...fieldValue, email];
      setSettings({ 'svrs_summary_emails': newEmails });
      updateSettings({ 'svrs_summary_emails': newEmails });
    }
  };

  const removeEmailFromSummaryEmails = (email) => {
    const fieldValue = tenant_settings.svrs_summary_emails;
    const newArray = fieldValue.filter((existingEmail) => existingEmail !== email);
    setSettings({ 'svrs_summary_emails': newArray });
    updateSettings({ 'svrs_summary_emails': newArray });
  };

  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState('');

  // Function to handle input change
  const handleInputChange = (event) => {
    setEmailInput(event.target.value);
  };

  // Function to handle button click
  const handleButtonClick = () => {
    if (validateEmail(emailInput)) {
      addEmailToSummaryEmails(emailInput);
      setEmailInput('');
      setEmailError('');
    } else {
      setEmailError('Invalid email format');
    }
  };

  return (
    <Panel>
      <Panel.Heading title={settingsLabels.panelTitle} />
      <Panel.Body>
        <div>
          <section className="setting-section">
            <TenantSettingTogglePanel
              className="small-12 medium-8 pl-0"
              options={optionsMeasures}
              value={measurement_system}
              onChange={updateSettings}
              settingKey="measurement_system"
            >
              {renderToggleTitle('weights', getOptLabel(optionsMeasures, measurement_system))}
            </TenantSettingTogglePanel>
          </section>
          <section className="setting-section">
            <TenantSettingTogglePanel
              className="small-12 medium-8 pl-0"
              options={optionsTemps}
              value={temperature_system}
              onChange={updateSettings}
              settingKey="temperature_system"
            >
              {renderToggleTitle('temperatureScale', getOptLabel(optionsTemps, temperature_system))}
            </TenantSettingTogglePanel>
          </section>

          {isSVREnabled && (
            <section className="setting-section">
              <ToggleSwitcher
                name="svrs_limited_access"
                className="small-12 medium-8"
                text={settingsLabels.reportsRestrictedUsers.text}
                description={settingsLabels.reportsRestrictedUsers.description}
                checked={svrs_limited_access}
                onChange={handleSettingChange}
              >
                <AsyncMultiSelect
                  values={company_svrs_disabled_users}
                  scrollable
                  filterOption={filterOption(company_svrs_disabled_users)}
                  className={cn('settings-multi-select', {disabled: !svrs_limited_access})}
                  selectClassName={cn('mt-10')}
                  onSelect={addUserToMultiSelectField('company_svrs_disabled_users')}
                  optionsPath="/users/search?with_current_user=true"
                  onRemove={removeUserFromMultiSelectField('company_svrs_disabled_users')}
                  optionKeys={{value: 'id', label: 'full_name'}}
                  placeholderKey="general.searchBy.users"
                  extractLabel={(value) => value.user.full_name}
                />
              </ToggleSwitcher>
            </section>
          )}

          <section className="setting-section">
            <ToggleSwitcher
              name="analytics_extended_access"
              className="small-12 medium-8"
              text={settingsLabels.extendedInsights.text}
              description={settingsLabels.extendedInsights.description}
              checked={analytics_extended_access}
              onChange={handleSettingChange}
            >
              <AsyncMultiSelect
                values={company_analytics_users}
                scrollable
                filterOption={filterOption(company_analytics_users)}
                className={cn('settings-multi-select', {disabled: !analytics_extended_access})}
                selectClassName={cn('mt-10')}
                onSelect={addUserToMultiSelectField('company_analytics_users')}
                optionsPath="/users/search?with_current_user=true"
                onRemove={removeUserFromMultiSelectField('company_analytics_users')}
                optionKeys={{value: 'id', label: 'full_name'}}
                placeholderKey="general.searchBy.users"
                extractLabel={(value) => value.user.full_name}
              />
            </ToggleSwitcher>
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="closeout_emails"
              className="small-12 medium-8"
              text={settingsLabels.closeoutEmails.text}
              description={settingsLabels.closeoutEmails.description}
              checked={closeout_emails}
              onChange={handleSettingChange}
            >
              <AsyncMultiSelect
                values={company_closeout_email_users}
                scrollable
                filterOption={filterOption(company_closeout_email_users)}
                className={cn('settings-multi-select', {disabled: !closeout_emails})}
                selectClassName={cn('mt-10')}
                onSelect={addUserToMultiSelectField('company_closeout_email_users')}
                optionsPath="/users/search?with_current_user=true"
                onRemove={removeUserFromMultiSelectField('company_closeout_email_users')}
                optionKeys={{value: 'id', label: 'full_name'}}
                placeholderKey="general.searchBy.users"
                extractLabel={(value) => value.user.full_name}
              />
            </ToggleSwitcher>
          </section>

          <section className="setting-section">
            <BasicPlanCustomizationBlocker>
              <ToggleSwitcher
                name="sms_compliance"
                className="small-12 medium-8"
                text={settingsLabels.sms.text}
                description={settingsLabels.sms.description}
                checked={sms_compliance}
                onChange={handleSettingChange}
              >
                <h3 className="body-title">
                  {settingsLabels.sms.remindHour}
                </h3>
                <ReactSelect
                  className="time-select"
                  value={farm_compliance_hour}
                  options={hours}
                  onChange={(option) => handleSelectChange('farm_compliance_hour')(option?.value)}
                  placeholder={<FormattedMessage id="general.selectHour"/>}
                  clearable={false}
                  disabled={!sms_compliance}
                />
                <h3 className="body-title">
                  {settingsLabels.sms.timeZone}
                </h3>
                <ReactSelect
                  className="time-select"
                  value={farm_compliance_utc_offset}
                  options={utcOffsets}
                  onChange={(option) => handleSelectChange('farm_compliance_utc_offset')(option?.value)}
                  placeholder={<FormattedMessage id="general.chooseTimeZone"/>}
                  clearable={false}
                  disabled={!sms_compliance}
                />
                <AttentionBox className="info-box">
                  <span className="info-text">
                    {settingsLabels.sms.reminderInfo(farm_compliance_hour, farm_compliance_utc_offset)}
                  </span>
                </AttentionBox>
              </ToggleSwitcher>
            </BasicPlanCustomizationBlocker>
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="barnsheets_limited_access"
              className="small-12 medium-8"
              text={settingsLabels.barnSheetsEditingConstraints.text}
              description={settingsLabels.barnSheetsEditingConstraints.description}
              checked={barnsheets_limited_access}
              onChange={handleSettingChange}
            />
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="inventory_reconciliation_active"
              className="small-12 medium-8"
              text={settingsLabels.inventory.text}
              description={settingsLabels.inventory.description}
              checked={inventory_reconciliation_active}
              onChange={handleSettingChange}
            >
              <h3 className="body-title">
                {settingsLabels.inventory.reconciliationTimeFrame}
              </h3>
              <ResponsiveSelect
                className="reconciliation-select"
                options={inventoryReconciliationOptions}
                onChange={handleSelectChange('inventory_reconciliation')}
                value={inventory_reconciliation}
                disabled={!inventory_reconciliation_active}
              />
              {inventory_reconciliation_active && (
                <AttentionBox className="info-box">
                  <span className="info-text">
                    {settingsLabels.inventory.reconciliationInfo(inventory_reconciliation)}
                  </span>
                </AttentionBox>
              )}
            </ToggleSwitcher>
          </section>

          <section className="setting-section">
            <BasicPlanCustomizationBlocker>
              <ToggleSwitcher
                name="treatment_compliance"
                className="small-12 medium-8"
                text={settingsLabels.treatmentCompliance.text}
                description={settingsLabels.treatmentCompliance.description}
                checked={treatment_compliance}
                onChange={handleSettingChange}
              >
                <h3 className="body-title">
                  {settingsLabels.treatmentCompliance.days}
                </h3>
                <div className="withdrawal">
                  <Input
                    type="text"
                    value={new_treatment_compliance_days}
                    onChange={setTreatmentComplianceDays}
                    disabled={!treatment_compliance}
                  />

                  {treatment_compliance && (
                    <Button
                      primary
                      className="save-weight-btn"
                      onClick={() => updateSettings({
                        treatment_compliance_days: new_treatment_compliance_days
                      })}
                      disabled={(treatment_compliance_days === new_treatment_compliance_days) || false}
                    >
                      {settingsLabels.save}
                    </Button>
                  )}
                </div>
              </ToggleSwitcher>
            </BasicPlanCustomizationBlocker>
          </section>

          {isTreatmentProtocolsFeatureEnabled && (
            <>
              <section className="setting-section">
                <ToggleSwitcher
                  name="treatment_protocols"
                  className="small-12 medium-8"
                  text={settingsLabels.treatmentProtocols.text}
                  description={settingsLabels.treatmentProtocols.description}
                  checked={treatment_protocols}
                  onChange={handleSettingChange}
                >
                  <div>
                    <h3 className={cn('body-title', 'mb-0')}>
                      {settingsLabels.treatmentProtocols.responsibleUsers.text}
                    </h3>
                    <span className="description">
                      {settingsLabels.treatmentProtocols.responsibleUsers.description}
                    </span>
                  </div>
                  <AsyncMultiSelect
                    values={treatment_protocol_contacts}
                    scrollable
                    filterOption={filterOption(treatment_protocol_contacts)}
                    className={cn('settings-multi-select', {disabled: !treatment_protocols})}
                    selectClassName={cn('mt-10')}
                    onSelect={addUserToMultiSelectField('treatment_protocol_contacts')}
                    optionsPath="/users/search?with_current_user=true"
                    onRemove={removeUserFromMultiSelectField('treatment_protocol_contacts')}
                    optionKeys={{value: 'id', label: 'full_name'}}
                    placeholderKey="general.searchBy.users"
                    extractLabel={(value) => value.user.full_name}
                  />
                </ToggleSwitcher>
              </section>
              <section className="setting-section">
                <ToggleSwitcher
                  name="treatment_protocols_pg_compliance"
                  className="small-12 medium-8"
                  text={settingsLabels.pigGroupsCompliance.text}
                  description={settingsLabels.pigGroupsCompliance.description}
                  checked={treatment_protocols_pg_compliance}
                  onChange={handleSettingChange}
                />
              </section>
            </>
          )}

          {isSVREnabled && (
            <>
              <section className="setting-section">
                <ToggleSwitcher
                  name="svrs_compliance_emails"
                  className="small-12 medium-8"
                  text={settingsLabels.complianceReport.text}
                  description={settingsLabels.complianceReport.description}
                  checked={svrs_compliance_emails}
                  onChange={handleSettingChange}
                />
              </section>
              <section className="setting-section">
                <ToggleSwitcher
                  name="farms_compliance_emails"
                  className="small-12 medium-8"
                  text={settingsLabels.complianceFarmReport.text}
                  description={settingsLabels.complianceFarmReport.description}
                  checked={farms_compliance_emails}
                  onChange={handleSettingChange}
                />
              </section>
            </>
          )}

          <section className="setting-section">
            <ToggleSwitcher
              name="svrs_daily_summary_emails"
              className="small-12 medium-8"
              text={settingsLabels.reportsDailySummary.text}
              description={settingsLabels.reportsDailySummary.description}
              checked={svrs_daily_summary_emails}
              onChange={handleSettingChange}
            >
              {emailError && (
                <MessageBox>
                  <FormattedMessage
                    id="component.userFormFields.invalidEmail"
                    values={{
                      title: <FormattedMessage tagName="b" id="component.userFormFields.invalidEmail.title"/>,
                    }}
                  />
                </MessageBox>
              )}
              <MultiInput
                values={svrs_summary_emails}
                scrollable
                filterOption={filterOption(svrs_daily_summary_emails)}
                className={cn('settings-multi-select', {disabled: !svrs_daily_summary_emails})}
                selectClassName={cn('input')}
                onSelect={handleInputChange}
                onClick={handleButtonClick}
                onRemove={removeEmailFromSummaryEmails}
                placeholderKey="general.typeEmail"
                extractLabel={(value) => value}
                inputValue={emailInput}
              />
            </ToggleSwitcher>
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="auto_report_emails"
              className="small-12 medium-8"
              text={settingsLabels.emails.text}
              description={settingsLabels.emails.description}
              checked={auto_report_emails}
              onChange={handleSettingChange}
            >
              <h3 className="body-title">
                {settingsLabels.emails.autoReportTimeFrame}
              </h3>
              <ResponsiveSelect
                className="report-select mr-15"
                options={autoReportPeriodOptions}
                onChange={handleSelectChange('auto_report_period')}
                value={auto_report_period}
                disabled={!auto_report_emails}
              />
            </ToggleSwitcher>
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="auto_report_mortality_percentage" // boolean value
              className="small-12 medium-8"
              text={settingsLabels.autoReportMortalityPercentage.text}
              description={settingsLabels.autoReportMortalityPercentage.description}
              checked={auto_report_mortality_percentage}
              onChange={handleSettingChange}
            />
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="general_water_usage_warnings" // boolean value
              className="small-12 medium-8"
              text={settingsLabels.waterConsumptionWarnings.text}
              description={settingsLabels.waterConsumptionWarnings.description}
              checked={general_water_usage_warnings}
              onChange={handleSettingChange}
            />
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="media_upload" // boolean value
              className="small-12 medium-8"
              text={settingsLabels.caregiverMediaUpload.text}
              description={settingsLabels.caregiverMediaUpload.description}
              checked={media_upload}
              onChange={handleSettingChange}
            />
          </section>

          <section className="setting-section">
            <BasicPlanCustomizationBlocker>
              <ToggleSwitcher
                name="withdrawal_tracking_on"
                className="small-12 medium-8"
                text={settingsLabels.withdrawal.text}
                description={settingsLabels.withdrawal.description}
                checked={withdrawal_tracking_on}
                onChange={handleSettingChange}
              >
                <h3 className="body-title">
                  {settingsLabels.withdrawal.withdrawalWeightThreshold}
                </h3>
                <div className="withdrawal">
                  <Input
                    type="text"
                    value={new_withdrawal_weight_limit}
                    onChange={setWeight}
                    disabled={!withdrawal_tracking_on}
                  />

                  <ResponsiveSelect
                    className="reconciliation-select"
                    options={withdrawalWeightOptions}
                    onChange={handleSelectChange('withdrawal_weight')}
                    value={measurement_system === 'imperial' ? 'lb' : 'kg'}
                    disabled
                  />

                  {withdrawal_tracking_on && (
                    <Button
                      primary
                      className="save-weight-btn"
                      onClick={() => updateSettings({
                        withdrawal_weight_limit: formatWeightToBackEndFormat(
                          new_withdrawal_weight_limit,
                          measurement_system
                        )
                      })}
                      disabled={(withdrawal_weight_limit === new_withdrawal_weight_limit) || false}
                    >
                      {settingsLabels.save}
                    </Button>
                  )}
                </div>
              </ToggleSwitcher>
            </BasicPlanCustomizationBlocker>
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="general_withdrawal_warnings" // boolean value
              className="small-12 medium-8"
              text={settingsLabels.generalWithdrawalWarnings.text}
              checked={general_withdrawal_warnings}
              onChange={handleSettingChange}
            />
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="track_mortality_reasons"
              className="small-12 medium-8"
              text={settingsLabels.mortality.text}
              description={settingsLabels.mortality.description}
              checked={track_mortality_reasons}
              onChange={handleSettingChange}
            >
              <Link to="/admin/health-variables/mortality-reasons" className="mt-15 mr-15 button light wider">
                {settingsLabels.mortality.manage}
              </Link>
            </ToggleSwitcher>
          </section>

          <section className="setting-section">
            <BasicPlanCustomizationBlocker>
              <ToggleSwitcher
                name="symptom_prompt"
                className="small-12 medium-8"
                text={settingsLabels.symptomPrompt.text}
                description={settingsLabels.symptomPrompt.description}
                checked={symptom_prompt}
                onChange={handleSettingChange}
              >
                <h3 className="body-title">
                  {settingsLabels.symptomPrompt.days}
                </h3>
                <div className="withdrawal">
                  <Input
                    type="text"
                    value={new_symptom_prompt_days}
                    onChange={setSymptomPromptDays}
                    disabled={!symptom_prompt}
                  />

                  {symptom_prompt && (
                    <Button
                      primary
                      className="save-weight-btn"
                      onClick={() => updateSettings({
                        symptom_prompt_days: new_symptom_prompt_days
                      })}
                      disabled={(symptom_prompt_days === new_symptom_prompt_days) || false}
                    >
                      {settingsLabels.save}
                    </Button>
                  )}
                </div>
              </ToggleSwitcher>
            </BasicPlanCustomizationBlocker>
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="abf_tracking"
              className="small-12 medium-8"
              text={settingsLabels.abfTracking.text}
              description={settingsLabels.abfTracking.description}
              checked={abf_tracking}
              onChange={handleSettingChange}
            />
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="feed_tracking"
              className="small-12 medium-8"
              text={settingsLabels.trackFeedOrders.text}
              description={settingsLabels.trackFeedOrders.description}
              checked={feed_tracking}
              onChange={handleSettingChange}
            />
          </section>

          <section className="setting-section">
            <BasicPlanCustomizationBlocker>
              <ToggleSwitcher
                name="comment_company_direct_post"
                className="small-12 medium-8"
                text={settingsLabels.comments.text}
                description={settingsLabels.comments.description}
                checked={comment_company_direct_post}
                onChange={handleSettingChange}
              />
            </BasicPlanCustomizationBlocker>
          </section>

          <DefaultMapCenterSection settings={tenant_settings} onUpdate={updateSettings}/>
        </div>
      </Panel.Body>
    </Panel>
  );
};

Settings.propTypes = {
  tenant_settings: T.object.isRequired,
  updateSettings: T.func.isRequired,
  setSettings: T.func.isRequired,
  isTreatmentProtocolsFeatureEnabled: T.bool.isRequired,
  isSVREnabled: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isSVREnabled: state.auth.user.current_company.admin_svrs,
    isTreatmentProtocolsFeatureEnabled: state.auth.user.current_company.admin_treatment_protocols,
  })
)(Settings);
